import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

const PrivateRoutes = () => {
  const ReportPage = lazy(() => import('../components/reports/ReportPage'))
  const Credits = lazy(() => import('../components/credits/Credits'))
  const AddCredits = lazy(() => import('../components/add_credits/AddCredits'))
  const Profile = lazy(() => import('../components/profile/Profile'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/agentapp' />} />
        {/* Pages */}
        <Route path='agentapp' element={<DashboardWrapper />} />

        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='reports'
          element={
            <SuspensedView>
              <ReportPage />
            </SuspensedView>
          }
        />
        <Route
          path='credits'
          element={
            <SuspensedView>
              <Credits />
            </SuspensedView>
          }
        />
        <Route
          path='profile'
          element={
            <SuspensedView>
              <Profile />
            </SuspensedView>
          }
        />
        {/* <Route
          path='reload-credits'
          element={
            <SuspensedView>
              <AddCredits />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='withdraw-commission'
          element={
            <SuspensedView>
              <WithdrawCommission />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='credit-conversion'
          element={
            <SuspensedView>
              <CreditConversion />
            </SuspensedView>
          }
        /> */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
